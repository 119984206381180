<template>
  <div>
    <v-container>
      <v-card elevation="4" outlined shaped color="main_color_dark">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1">
              {{ rows[id].name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-avatar
                v-for="m in rows[id].members"
                class="mr-1"
                :key="m.name"
                size="48"
                max-height
              >
                <v-img :src="m.thumbnail" :alt="m.name"></v-img>
              </v-avatar>
            </v-list-item-subtitle>
            <div class="overline">
              <v-chip-group>
                <v-chip
                  v-for="pj in rows[id].projects"
                  :key="pj"
                  :ripple="false"
                  small
                  outlined
                >
                  {{ pj }}
                </v-chip>
              </v-chip-group>
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-card-text>
          <h3 class="mb-4">Evaluation for {{ rows[id].name }}</h3>
          <div v-for="ev in rows[id].evaluations" :key="ev.question">
            <p>{{ ev.id }}. {{ ev.question }}</p>

            <v-radio-group row v-model="ev.value">
              <v-radio color="orange" label="Yes" :value="true"></v-radio>
              <v-radio color="orange" label="No" :value="false"></v-radio>
            </v-radio-group>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text>
          <submit-button :onSubmit="submit" :isSubmitted="isSubmitted" />
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import util from '@/assets/util.js';
import SubmitButton from '@/components/SubmitButton';

export default {
  name: 'TeamEvaluation',

  components: {
    'submit-button': SubmitButton,
  },

  created() {
    document.title = `Team Evaluations - MEVN Kickoff ${util.kickoffDate}`;
  },

  data() {
    const members = util.loadStorage('config/members');
    const teams = util.loadStorage('config/teams');
    const team_evs = util.loadStorage('config/team_evaluations');

    const _teams = teams.map((t, index) => {
      t.id = index;
      t.members = t.members.map((email) =>
        members.find((m) => m.email === email)
      );
      return Object.assign(t, util.clone(team_evs));
    });

    return {
      rows: _teams.filter((t) => {
        const key = this.genStorageKey(t.name);
        const data = this.loadStorage(key);

        if (data === null) {
          return t;
        }

        t.evaluations.map((ev, i) => {
          ev.value = data.evaluations[i];
          return ev;
        });
        return t;
      }),
    };
  },

  computed: {
    id() {
      return parseInt(this.$route.params.id);
    },

    isSubmitted() {
      const key = this.genStorageKey(this.rows[this.id].name);
      return localStorage.getItem(key) !== null;
    },
  },

  methods: {
    // On clicked submit button
    async submit() {
      const endpoint = this.$route.meta.submitEndpoint;
      const name = this.rows[this.id].name;
      const key = this.genStorageKey(name);

      // Finish if already sent data
      if (this.loadStorage(key) !== null) {
        return false;
      }

      const data = {
        user_email: sessionStorage.getItem('userEmail'),
        name,
        evaluations: this.rows[this.id].evaluations.map((ev) => ev.value),
      };

      try {
        const axios_config = {
          headers: {
            token: process.env.VUE_APP_AUTHENTICATION_TOKEN,
          },
        };

        // Submit data to server
        const response = await axios.post(endpoint, data, axios_config);

        // Save local storage
        this.saveStorage(key, data);

        // Reload current page
        this.reload();
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    },

    // Generate localstorage key
    genStorageKey(name) {
      return `${this.$options.name}/${name}`;
    },

    // load and save local storage
    loadStorage: util.loadStorage,
    saveStorage: util.saveStorage,

    // Reload page
    reload() {
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
  },
};
</script>
