export default {
  // Kickoff date string
  kickoffDate: '2024/12/06',

  // Cloning object
  clone: (obj) => JSON.parse(JSON.stringify(obj)),

  // Remove item from array
  arrayRemove(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  },

  // load key from local storage
  loadStorage: (key) => JSON.parse(localStorage.getItem(key)),

  // Save local storage
  saveStorage: (key, data) => localStorage.setItem(key, JSON.stringify(data)),
};
