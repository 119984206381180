<template>
  <div>
    <v-container>
      <v-card elevation="4" outlined shaped color="main_color_dark">
        <v-list-item three-line>
          <v-list-item-content>
            <div class="overline">
              {{ rows[id].team ? rows[id].team + ' TEAM' : '' }}
            </div>
            <v-list-item-title class="title">
              {{ rows[id].name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-chip-group>
                <v-chip :ripple="false" small outlined>
                  {{ rows[id].position }}
                </v-chip>
                <v-chip color="orange" :ripple="false" small outlined>
                  {{ rows[id].hire_date }}
                </v-chip>
              </v-chip-group>
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-avatar color="grey" size="100"
            ><v-img max-height :src="rows[id].thumbnail"></v-img
          ></v-list-item-avatar>
        </v-list-item>

        <v-divider></v-divider>

        <v-card-text>
          <h3 class="mb-4">Evaluation for {{ rows[id].name }}</h3>

          <div v-for="ev in rows[id].evaluations" :key="ev.question">
            <p>{{ ev.id }}. {{ ev.question }}</p>

            <v-radio-group row v-model="ev.value" :value="ev.value">
              <v-radio color="orange" label="Yes" :value="true"></v-radio>
              <v-radio color="orange" label="No" :value="false"></v-radio>
            </v-radio-group>
          </div>

          <h3 class="mt-4 mb-4">Feedback for {{ rows[id].name }}</h3>

          <div v-for="fb in rows[id].feedbacks" :key="fb.question">
            <p>{{ fb.id }}. {{ fb.question }}</p>

            <v-textarea
              label="Please write feedback"
              auto-grow
              outlined
              rows="1"
              row-height="15"
              v-model="fb.value"
            ></v-textarea>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text>
          <submit-button :onSubmit="submit" :isSubmitted="isSubmitted" />
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import util from '@/assets/util.js';
import SubmitButton from '@/components/SubmitButton';

export default {
  name: 'MemberEvaluation',

  components: {
    'submit-button': SubmitButton,
  },

  created() {
    document.title = `Member Evaluations - MEVN Kickoff ${util.kickoffDate}`;
  },

  data() {
    const members = util.loadStorage('config/members');
    const teams = util.loadStorage('config/teams');
    const member_evs = util.loadStorage('config/member_evaluations');

    const getTeam = (email) => {
      for (let t of teams) {
        if (t.members.find((e) => e === email)) {
          return t.name;
        }
      }
    };

    const _members = members.map((m, index) => {
      m.id = index;
      m.team = getTeam(m.email);
      return Object.assign(m, util.clone(member_evs));
    });

    return {
      // Get data from localstorage
      rows: _members.filter((m) => {
        const key = this.genStorageKey(m.email);
        const data = this.loadStorage(key);

        if (data === null) {
          return m;
        }

        m.evaluations.map((ev, i) => {
          ev.value = data.evaluations[i];
          return ev;
        });
        m.feedbacks.map((fb, i) => {
          fb.value = data.feedbacks[i];
          return fb;
        });
        return m;
      }),
    };
  },

  computed: {
    id() {
      return parseInt(this.$route.params.id);
    },

    isSubmitted() {
      const key = this.genStorageKey(this.rows[this.id].email);
      return localStorage.getItem(key) !== null;
    },
  },

  methods: {
    // On clicked submit button
    async submit() {
      const endpoint = this.$route.meta.submitEndpoint;
      const email = this.rows[this.id].email;
      const key = this.genStorageKey(email);

      // Finish if already sent data
      if (this.loadStorage(key) !== null) {
        return false;
      }

      const data = {
        user_email: sessionStorage.getItem('userEmail'),
        email,
        evaluations: this.rows[this.id].evaluations.map((ev) => ev.value),
        feedbacks: this.rows[this.id].feedbacks.map((ev) => ev.value),
      };

      const axios_config = {
        headers: {
          token: process.env.VUE_APP_AUTHENTICATION_TOKEN,
        },
      };

      try {
        // Submit data to server
        const response = await axios.post(endpoint, data, axios_config);

        // Save local storage
        this.saveStorage(key, data);

        // Reload current page
        this.reload();
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    },

    // Reload page
    reload() {
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },

    // Generate localstorage key
    genStorageKey(email) {
      return `${this.$options.name}/${email}`;
    },

    // load and save local storage
    loadStorage: util.loadStorage,
    saveStorage: util.saveStorage,
  },
};
</script>
