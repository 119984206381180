<template>
  <div class="home">
    <v-container>
      <div class="text-center">
        <h1 class="home_title font-weight-bold mt-2 mb-0 text-white">
          QUARTER SHARING
        </h1>
        <p class="subheading mt-1 mb-2 font-weight-regular">6th Dec 2024</p>
      </div>
    </v-container>

    <div class="mt-2">
      <v-img src="/images/lead/banner.jpg"></v-img>
    </div>

    <v-container>
      <div class="text-center mt-4 mb-0">
        <h2 class="display-1 font-weight-bold text-white">INTELLIGENT X</h2>
      </div>
    </v-container>

    <v-container>
      <div class="text-center mt-2">
        <v-avatar
          v-for="r in members"
          :key="r.email"
          color="grey"
          size="48"
          class="ma-2"
          ><v-img max-height :src="r.thumbnail"></v-img
        ></v-avatar>
      </div>
    </v-container>
  </div>
</template>

<script>
import util from '@/assets/util';

export default {
  name: 'Home',
  components: {},

  created() {
    document.title = `Home - MEVN Kickoff ${util.kickoffDate}`;
  },

  data() {
    const members = util.loadStorage('config/members');
    return {
      members,
    };
  },
};
</script>

<style scoped>
p.subheading {
  margin-bottom: 0;
}
.home_title {
  font-size: 2em;
  font-weight: bold;
}
</style>
