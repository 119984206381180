<template>
  <div>
    <v-container v-for="(row, index) in rows" :key="row.name">
      <v-card elevation="8" outlined shaped color="main_color_dark">
        <v-list-item two-line>
          <v-list-item-content>
            <h3 class="mt-2 mb-2">
              {{ index + 1 }}. {{ row.name }}
              <v-chip color="orange" :ripple="false" small>
                <v-icon left> mdi-account-group </v-icon>
                {{ row.number_of_engineers }}
              </v-chip>
            </h3>
            <v-list-item-title class="headline rounded-lg">
              <v-img max-width :src="row.thumbnail"></v-img>
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-chip-group>
                <v-chip :ripple="false" small outlined>
                  {{ row.pm }}
                </v-chip>
              </v-chip-group>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-card-text>
          <h2 v-html="row.goal.replace(/\n/g,'<br/>')"></h2>
        </v-card-text> -->
        <v-card-text class="py-0 fs-1rem">
          <p class="mb-0" v-html="row.description.replace(/\n/g, '<br/>')" />
        </v-card-text>

        <v-card-text>
          <v-btn
            block
            v-if="choosePjs.indexOf(row.name) === -1"
            @click="clickedChoosePj(row.name, false)"
            depressed
            large
            color="main_color_light"
            >CHOOSE</v-btn
          >

          <v-btn
            v-else
            @click="clickedChoosePj(row.name, true)"
            text
            large
            class="title green"
            block
          >
            <v-icon left class="headline"> mdi-check </v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>

    <v-container>
      <submit-button :onSubmit="submit" :isSubmitted="isSubmitted" />
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import util from '@/assets/util.js';
import SubmitButton from '@/components/SubmitButton';

export default {
  name: 'SubPjSelection',

  components: {
    'submit-button': SubmitButton,
  },

  created() {
    document.title = `Sub Project Selections - MEVN Kickoff ${util.kickoffDate}`;
  },

  data() {
    const projects = util.loadStorage('config/new_sub_projects');

    const key = this.genStorageKey();
    const data = this.loadStorage(key);

    return {
      pjsLimit: 1,
      choosePjs: data !== null ? data.pjs : [],
      rows: projects,
    };
  },

  computed: {
    isSubmitted() {
      const key = this.genStorageKey();
      return localStorage.getItem(key) !== null;
    },
  },

  methods: {
    clickedChoosePj(name, isCancel) {
      // When limited count
      if (!isCancel && this.choosePjs.length >= this.pjsLimit) {
        return;
      }

      !isCancel
        ? this.choosePjs.push(name)
        : util.arrayRemove(this.choosePjs, name);
    },

    async submit() {
      if (this.choosePjs.length !== this.pjsLimit) {
        alert('You have to choose ' + this.pjsLimit + ' project.');
        this.reload();
        return false;
      }

      const endpoint = this.$route.meta.submitEndpoint;
      const key = this.genStorageKey();

      // Finish if already sent data
      if (this.loadStorage(key) !== null) {
        return false;
      }

      const data = {
        user_email: sessionStorage.getItem('userEmail'),
        pjs: this.choosePjs,
      };

      try {
        const axios_config = {
          headers: {
            token: process.env.VUE_APP_AUTHENTICATION_TOKEN,
          },
        };

        // Submit data to server
        const response = await axios.post(endpoint, data, axios_config);

        // Save local storage
        this.saveStorage(key, data);

        // Reload current page
        this.reload();
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    },

    // Generate localstorage key
    genStorageKey() {
      return this.$options.name;
    },

    // load and save local storage
    loadStorage: util.loadStorage,
    saveStorage: util.saveStorage,

    // Reload page
    reload() {
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
  },
};
</script>
