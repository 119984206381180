'use strict';

import Vue from 'vue';
import axios from 'axios';
import App from './App.vue';
import getRouter from './router';
import vuetify from './plugins/vuetify';
import util from '@/assets/util';

Vue.config.productionTip = false;

const authentication_token = process.env.VUE_APP_AUTHENTICATION_TOKEN;
const axios_config = {
  headers: {
    token: authentication_token,
  },
};

const api_endpoint = process.env.VUE_APP_API_ENDPOINT;
const paths = [
  'config/features',
  'config/members',
  'config/teams',
  'config/sub_teams',
  'config/member_evaluations',
  'config/team_evaluations',
  'config/sub_team_evaluations',
  'config/new_projects',
  'config/new_sub_projects',
];

async function initialize() {
  // Clear local storage
  const userEmail = sessionStorage.getItem('userEmail');
  if (userEmail !== null) {
    const memberSession = await axios.get(
      `${api_endpoint}/member_session/${userEmail}`,
      axios_config
    );
    if (memberSession.data === false) {
      localStorage.clear();
    }
  }

  // Parare loading config from api server and save to localStorage
  await Promise.all(
    paths.map(async (p) =>
      axios
        .get(`${api_endpoint}/${p}`, axios_config)
        .then((r) => util.saveStorage(p, r.data))
    )
  ).then(() => {
    new Vue({
      router: getRouter(),
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  });
}

initialize();
