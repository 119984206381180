<template>
  <div>
    <v-container v-for="row in rows" :key="row.email">
      <v-card elevation="4" shaped color="main_color_dark">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              <v-avatar color="grey" size="48" class="mr-2"
                ><v-img max-height :src="row.thumbnail"></v-img
              ></v-avatar>
              {{ row.short_name }}
            </v-list-item-title>
          </v-list-item-content>

          <v-btn
            v-if="choosePms.indexOf(row.email) === -1"
            @click="clickedChoosePm(row.email, false)"
            rounded
            large
            color="main_color_light"
          >
            CHOOSE
          </v-btn>

          <v-btn
            v-else
            @click="clickedChoosePm(row.email, true)"
            text
            large
            class="title green"
            rounded
          >
            {{ choosePms.indexOf(row.email) + 1 }}
          </v-btn>
        </v-list-item>
      </v-card>
    </v-container>

    <v-container>
      <submit-button :onSubmit="submit" :isSubmitted="isSubmitted" />
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import util from '@/assets/util.js';
import SubmitButton from '@/components/SubmitButton';

export default {
  name: 'PmSelection',

  components: {
    'submit-button': SubmitButton,
  },

  created() {
    document.title = `PM Selections - MEVN Kickoff ${util.kickoffDate}`;
  },

  data() {
    const members = util.loadStorage('config/members');
    const projects = util.loadStorage('config/new_projects');
    const email = sessionStorage.getItem('userEmail');
    const key = this.genStorageKey();
    const data = this.loadStorage(key);

    return {
      pmsLimit: projects.length,
      choosePms: data !== null ? data.pms : [],
      rows: members.filter((m) => !m.ignore_pm_candidate && m.email !== email),
    };
  },

  computed: {
    isSubmitted() {
      const key = this.genStorageKey();
      return localStorage.getItem(key) !== null;
    },
  },

  methods: {
    clickedChoosePm(email, isCancel) {
      // When limited count
      if (!isCancel && this.choosePms.length >= this.pmsLimit) {
        return;
      }

      !isCancel
        ? this.choosePms.push(email)
        : util.arrayRemove(this.choosePms, email);
    },

    async submit() {
      if (this.choosePms.length !== this.pmsLimit) {
        alert('You have to choose ' + this.pmsLimit + ' PMs.');
        this.reload();
        return false;
      }

      const endpoint = this.$route.meta.submitEndpoint;
      const key = this.genStorageKey();

      // Finish if already sent data
      if (this.loadStorage(key) !== null) {
        return false;
      }

      const data = {
        user_email: sessionStorage.getItem('userEmail'),
        pms: this.choosePms,
      };

      try {
        const axios_config = {
          headers: {
            token: process.env.VUE_APP_AUTHENTICATION_TOKEN,
          },
        };

        // Submit data to server
        const response = await axios.post(endpoint, data, axios_config);

        // Save local storage
        this.saveStorage(key, data);

        // Reload current page
        this.reload();
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    },

    // Generate localstorage key
    genStorageKey() {
      return this.$options.name;
    },

    // load and save local storage
    loadStorage: util.loadStorage,
    saveStorage: util.saveStorage,

    // Reload page
    reload() {
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
  },
};
</script>

<style scoped>
.v-application .v-list-item__title.title {
  font-size: 1.1rem !important;
}
</style>
