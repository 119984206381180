<template>
  <v-app>
    <!-- Header bar -->
    <v-app-bar v-if="isSmartphone" app color="main_color" light>
      <!-- Logo -->
      <div class="d-flex align-center">
        <router-link to="/">
          <v-img
            alt="MarketentErprise Logo"
            class="shrink mr-3"
            contain
            src="https://mevn-public.s3.ap-southeast-1.amazonaws.com/marketenterprise.vn/images/mevn_logo.svg"
            transition="scale-transition"
            width="100"
          />
        </router-link>
      </div>
      <!-- / Logo -->

      <!-- Page title -->
      <div v-if="loggedin" class="text-white">
        {{ currentRouteName == 'Home' ? '' : currentRouteName }}
      </div>

      <div v-else>MEVN KICK-OFF</div>
      <!-- / Page title -->

      <v-spacer></v-spacer>

      <!-- Menu toggle button -->
      <v-app-bar-nav-icon
        v-if="loggedin"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <!-- /  Menu toggle button -->
    </v-app-bar>
    <!-- / Header bar -->

    <!-- Main Contents Area -->
    <v-main class="main_color main_bg">
      <!-- Case not smartphone -->
      <v-container v-if="!isSmartphone">
        You can access with mobile device only.
      </v-container>

      <!-- Case not loggedin -->
      <v-container v-else-if="!loggedin">
        <v-img
          class="ma-4 mb-8 philosophy"
          src="https://mevn-public.s3-ap-southeast-1.amazonaws.com/marketenterprise.vn/images/philosophy.svg"
        />

        <google-login-button
          :params="googleLoginParams"
          :renderParams="googleLoginRenderParams"
          :onSuccess="onLoginSuccess"
          :onFailure="onLoginFailure"
        ></google-login-button>
      </v-container>

      <!-- Case not loggedin -->
      <router-view v-else></router-view>
    </v-main>
    <!-- / Main Contents Area -->

    <!-- ASIDE -->
    <v-navigation-drawer
      v-if="loggedin"
      v-model="drawer"
      fixed
      temporary
      app
      class="main_color"
    >
      <!-- User avatar -->
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-img :src="userThumbnail" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ userName }}</v-list-item-title>
            <v-list-item-subtitle>{{ userEmail }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <!-- / User avatar -->

      <v-divider></v-divider>

      <v-list>
        <div v-for="item in items" :key="item.title">
          <v-list-item
            v-if="!item.children"
            :to="item.path"
            :disabled="item.disabled"
          >
            <v-list-item-title>
              <span v-if="!item.needCompleted">{{ item.title }}</span>

              <v-badge
                v-else-if="item.completed"
                color="green"
                icon="mdi-check-bold"
                offset-x="2"
                offset-y="12"
                >{{ item.title }}</v-badge
              >

              <v-badge v-else color="orange" dot>{{ item.title }}</v-badge>
            </v-list-item-title>
          </v-list-item>

          <v-list-group v-else v-model="item.active" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <span v-if="!item.needCompleted">{{ item.title }}</span>

                  <v-badge
                    v-else-if="item.completed"
                    color="green"
                    icon="mdi-check-bold"
                    offset-x="2"
                    offset-y="12"
                    >{{ item.title }}</v-badge
                  >

                  <v-badge v-else color="orange" class="mt-0" dot>{{
                    item.title
                  }}</v-badge>
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              class="pl-6"
              v-for="child in item.children"
              :key="child.title"
              :to="child.path"
              :disabled="child.own || child.disabled"
              v-show="!child.ignore_member_evaluation"
            >
              <v-list-item-icon class="mr-2" v-if="child.thumbnail">
                <v-avatar size="24">
                  <v-img :src="child.thumbnail" :alt="child.name"></v-img>
                </v-avatar>
              </v-list-item-icon>

              <v-list-item-title>
                <span v-if="!child.needCompleted">{{ child.name }}</span>
                <span v-else-if="child.own">{{ child.name }} (You)</span>

                <v-badge
                  v-else-if="child.completed"
                  color="green"
                  icon="mdi-check-bold"
                  offset-x="2"
                  offset-y="12"
                  >{{ child.name }}</v-badge
                >

                <v-badge v-else color="orange" dot>{{ child.name }}</v-badge>
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>

        <v-list-item @click="logout">
          <v-list-item-title> Logout </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- / ASIDE -->
  </v-app>
</template>

<script>
import GoogleLogin from 'vue-google-login';
import util from '@/assets/util';

export default {
  name: 'App',

  components: {
    'google-login-button': GoogleLogin,
  },

  created() {
    document.title = `MEVN Kickoff ${util.kickoffDate}`;
  },

  data() {
    const userEmail = sessionStorage.getItem('userEmail');

    return {
      members: util.loadStorage('config/members'),
      drawer: false,
      // client_id is the only required property but you can add several more params,
      // full list down bellow on the Auth api section
      googleLoginParams: {
        client_id:
          '286517479448-272uok4ug43aue3k7d81cnm953r5g618.apps.googleusercontent.com',
      },

      // only needed if you want to render the button with the google ui
      googleLoginRenderParams: {
        width: document.body.clientWidth - 24,
        height: 50,
        longtitle: true,
        theme: 'dark',
      },

      // Check device
      isSmartphone: navigator.userAgent.match(/iPhone|Android.+Mobile/),

      // Loggedin user infomation
      loggedin: !!userEmail,
      userName: sessionStorage.getItem('userName'),
      userThumbnail: sessionStorage.getItem('userThumbnail'),
      userEmail: userEmail,
    };
  },

  computed: {
    // Return to current page name
    currentRouteName() {
      return this.$route.name;
    },

    items() {
      // Create menu list from routes with showMenu option
      const items = [];
      for (let r of this.$router.options.routes.filter(
        (r) => r.meta.showMenu
      )) {
        // When menu has children
        if (r.meta.children) {
          const children = r.meta.children.map((c) => {
            const key = `${r.component.name}/${c[r.meta.childKey]}`;
            c.completed = localStorage.getItem(key) !== null || c.own;
            return c;
          });
          children.sort((a, b) => a.presentation_order - b.presentation_order);

          items.push({
            title: r.name,
            needCompleted: r.meta.needCompleted,
            completed: !children.find((c) => !c.completed),
            disabled: r.meta.disabled,
            children,
          });
        }

        // No children
        else {
          items.push({
            title: r.name,
            path: r.path,
            needCompleted: r.meta.needCompleted,
            completed: localStorage.getItem(r.component.name) !== null,
            disabled: r.meta.disabled,
          });
        }
      }

      return items;
    },
  },

  methods: {
    // For google login
    onLoginSuccess(googleUser) {
      // This only gets the user information: id, name, imageUrl and email
      const guser = googleUser.getBasicProfile();
      const email = guser.getEmail();

      // Not emproee
      if (!email.match(/@marketenterprise\.(vn|co\.jp)$/)) {
        this.onLoginFailure();
        return false;
      }

      // Get user data from app
      const user = this.members.find((m) => m.email === email);

      // EMail
      this.userEmail = email;

      // members
      if (user) {
        this.userName = user.name;
        this.userThumbnail = user.thumbnail;
      }

      // Case Maruo
      else {
        // Name
        this.userName = guser.getName();
        // Thumbnail
        this.userThumbnail = guser.getImageUrl();
      }

      this.loggedin = true;

      sessionStorage.setItem('userEmail', this.userEmail);
      sessionStorage.setItem('userName', this.userName);
      sessionStorage.setItem('userThumbnail', this.userThumbnail);

      // Reload current page
      this.reload();
    },

    // For google login
    onLoginFailure(err) {
      alert(`Login failure: ${err}`);
    },

    // logout
    logout() {
      sessionStorage.removeItem('userEmail');
      sessionStorage.removeItem('userName');
      sessionStorage.removeItem('userThumbnail');

      // Reload current page
      this.reload();
    },

    // Reload page
    reload() {
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
  },
};
</script>

<style>
/* .philosophy {
  filter: invert(1);
} */
.main_bg {
  background-image: url('/images/lead/bg.jpg');
  background-size: cover;
  background-repeat: repeat;
}
.v-application .main_color_dark {
  background-color: rgba(0, 11, 40, 0.7) !important;
  border-color: rgba(0, 11, 40, 0.7) !important;
}
.text-orange {
  color: #ff9800 !important;
}
.theme--light.v-icon {
  color: #ffffff !important;
}
.theme--light.v-sheet {
  color: #ffffff !important;
}
.v-list .v-list-item--active {
  color: #ffffff !important;
}
.theme--light.v-list-item--disabled,
.theme--dark.v-list-item--disabled,
.theme--light.v-list-item--disabled *,
.theme--dark.v-list-item--disabled * {
  pointer-events: none !important;
  cursor: default !important;
  text-decoration: none !important;
}
.fs-1rem {
  font-size: 1rem !important;
}
.container {
  padding: 8px 12px;
}
.v-main__wrap {
  margin-top: 6px;
  margin-bottom: 6px;
}
.theme--light.v-card > .v-card__text,
.theme--light.v-card > .v-card__subtitle {
  color: rgba(0, 0, 0, 0.9) !important;
}
.theme--light.v-label {
  color: rgba(0, 0, 0, 0.9) !important;
}
.theme--dark.v-application {
  color: rgba(255, 255, 255, 0.9) !important;
}
.v-application .display-1 {
  font-size: 1.725rem !important;
}
.theme--dark.v-card > .v-card__text,
.theme--dark.v-card > .v-card__subtitle {
  color: rgba(255, 255, 255, 0.9) !important;
}
.theme--dark.v-navigation-drawer {
  min-width: 280px;
}
.v-list-item__content .v-list-item__title {
  white-space: pre-line;
  line-height: 2;
}
.v-navigation-drawer__content .v-list-item .v-list-item__title,
.v-navigation-drawer__content .v-list-item .v-list-item__subtitle {
  line-height: 2;
}
.v-list-item__content .v-list-item__title {
  line-height: 1.4;
}
.v-navigation-drawer__prepend .v-list-item .v-list-item__title {
  line-height: 1.2;
}
.v-list-item__title > .v-badge {
  margin-top: 0;
}
.v-list-item .v-list-item__icon {
  margin-left: 5px !important;
}
.v-list-group
  .v-list-group__header
  .v-list-item__icon.v-list-group__header__append-icon {
  min-width: 24px;
}
.v-btn.v-size--x-large {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}
.v-btn.active {
  min-width: 103px !important;
  background-color: #ff9800 !important;
  color: #fff !important;
}
.v-application .button_bg {
  color: #fff !important;
}
.v-btn--block {
  width: 100% !important;
}
.v-chip-group .v-chip {
  min-height: 25px !important;
}
.v-chip-group .v-slide-group__content {
  padding: 5px 0 !important;
}
</style>
