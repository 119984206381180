<template>
  <div>
    <v-btn 
    v-if="!isSubmitted"
    block
    x-large
    depressed
    :disabled="lock"
    color="button_bg"
    @click="onClick">
      <v-icon left>
        mdi-share
      </v-icon>
      {{label}}
    </v-btn>

    <v-btn 
    v-else
    block
    x-large
    text
    color="orange"
    disabled>
      <v-icon left>
        mdi-check
      </v-icon>
      SUBMITTED
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    onSubmit: {
      type: Function,
      required: true
    },
    isSubmitted: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      lock: false,
      label: 'SUBMIT'
    }
  },

  methods: {
    async onClick () {
      this.lock = true
      this.label = 'SUBMITTING...'
      await this.onSubmit()
    }
  }
}
</script>