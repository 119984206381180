'use strict';

import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    light: false,
    themes: {
      dark: {
        main_color: '#000B28',
        // main_color_dark: '#000B28',
        main_color_light: '#43A047',
        button_bg: '#ef8200',
      },
      light: {
        main_color: '#f1f0e7',
        // main_color_dark: '#fef1dc',
        main_color_light: '#64B5F6',
        button_bg: '#FF9800',
      },
    },
  },
});
