'use strict';

import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import TeamEvaluation from '../views/TeamEvaluation.vue';
import SubProjectEvaluation from '../views/SubProjectEvaluation.vue';
import MemberEvaluation from '../views/MemberEvaluation.vue';
import PmSelection from '../views/PmSelection.vue';
import TechLeadSelection from '../views/TechLeadSelection.vue';
import ValueLeadSelection from '../views/ValueLeadSelection.vue';
import PjSelection from '../views/PjSelection.vue';
import SubPjSelection from '../views/SubPjSelection.vue';

Vue.use(VueRouter);

export default () => {
  const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;

  // Only logged in
  const email = sessionStorage.getItem('userEmail');

  // Load member config from local storage
  const members = JSON.parse(localStorage.getItem('config/members'));

  // Current user data
  const currentUser = members.find((member) => member.email === email);

  // Define routes
  const routes = [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        showMenu: true,
        disabled: false,
      },
    },
  ];

  // Load features from local storage
  const features = JSON.parse(localStorage.getItem('config/features'));

  // Handle isMemberEvaluation
  if (features['member_evaluation'] === true) {
    routes.push({
      path: '/member_evaluation/:id',
      name: 'Member Evaluation',
      component: MemberEvaluation,
      meta: {
        showMenu: true,
        disabled: false,
        needCompleted: true,
        children: members.map((m, index) => {
          m.path = {
            name: 'Member Evaluation',
            params: { id: index },
          };
          m.needCompleted = true;
          m.own = m.email === email;
          m.disabled =
            typeof currentUser == 'undefined' ||
            currentUser.ignore_member_evaluation == true;
          return m;
        }),
        childKey: 'email',
        submitEndpoint: `${API_ENDPOINT}/member_evaluation`,
      },
    });
  }

  // Handle isTeamEvaluation
  const teams = JSON.parse(localStorage.getItem('config/teams'));
  if (features['team_evaluation'] === true) {
    routes.push({
      path: '/team_evaluation/:id',
      name: 'Team Evaluation',
      component: TeamEvaluation,
      meta: {
        showMenu: true,
        disabled: false,
        needCompleted: true,
        children: teams.map((t, index) => {
          t.path = { name: 'Team Evaluation', params: { id: index } };
          t.needCompleted = true;
          t.own = t.members.indexOf(email) !== -1;
          t.disabled =
            typeof currentUser == 'undefined' ||
            currentUser.ignore_team_evaluation == true;
          return t;
        }),
        childKey: 'name',
        submitEndpoint: `${API_ENDPOINT}/team_evaluation`,
      },
    });
  }

  // Handle isSubProjectEvaluation
  const sub_teams = JSON.parse(localStorage.getItem('config/sub_teams'));
  if (features['sub_project_evaluation'] === true) {
    routes.push({
      path: '/sub_team_evaluation/:id',
      name: 'Sub Project Evaluation',
      component: SubProjectEvaluation,
      meta: {
        showMenu: true,
        disabled: false,
        needCompleted: true,
        children: sub_teams.map((t, index) => {
          t.path = {
            name: 'Sub Project Evaluation',
            params: { id: index },
          };
          t.needCompleted = true;
          t.own = t.members.indexOf(email) !== -1;
          t.disabled =
            typeof currentUser == 'undefined' ||
            currentUser.ignore_team_evaluation == true;
          return t;
        }),
        childKey: 'name',
        submitEndpoint: `${API_ENDPOINT}/sub_team_evaluation`,
      },
    });
  }

  // Handle isPmSelection
  if (features['pm_selection'] === true) {
    routes.push({
      path: '/pm_selection',
      name: 'PM Selection',
      component: PmSelection,
      meta: {
        showMenu: true,
        disabled:
          typeof currentUser == 'undefined' ||
          currentUser.ignore_selection == true,
        needCompleted: true,
        submitEndpoint: `${API_ENDPOINT}/pm_selection`,
      },
    });
  }

  // Handle isTechLead_selection
  if (features['tech_lead_selection'] === true) {
    routes.push({
      path: '/tech_lead_selection',
      name: 'TechLead Selection',
      component: TechLeadSelection,
      meta: {
        showMenu: true,
        disabled:
          typeof currentUser == 'undefined' ||
          currentUser.ignore_selection == true,
        needCompleted: true,
        submitEndpoint: `${API_ENDPOINT}/tech_lead_selection`,
      },
    });
  }

  // Handle isValueLeadSelection
  if (features['value_lead_selection'] === true) {
    routes.push({
      path: '/value_lead_selection',
      name: 'ValueLead Selection',
      component: ValueLeadSelection,
      meta: {
        showMenu: true,
        disabled:
          typeof currentUser == 'undefined' ||
          currentUser.ignore_selection == true,
        needCompleted: true,
        submitEndpoint: `${API_ENDPOINT}/value_lead_selection`,
      },
    });
  }

  // Handle isProjectSelection
  if (features['project_selection'] === true) {
    routes.push({
      path: '/pj_selection',
      name: 'Project Selection',
      component: PjSelection,
      meta: {
        showMenu: true,
        disabled:
          typeof currentUser == 'undefined' ||
          currentUser.ignore_selection == true,
        needCompleted: true,
        submitEndpoint: `${API_ENDPOINT}/pj_selection`,
      },
    });
  }

  // Handle isSubProjectSelection
  if (features['sub_project_selection'] === true) {
    routes.push({
      path: '/sub_pj_selection',
      name: 'Sub Project Selection',
      component: SubPjSelection,
      meta: {
        showMenu: true,
        disabled:
          typeof currentUser == 'undefined' ||
          currentUser.ignore_selection == true,
        needCompleted: true,
        submitEndpoint: `${API_ENDPOINT}/sub_pj_selection`,
      },
    });
  }

  /**
   * Data for ignore routes
   *
   * ignore_member_evaluation
   * ignore_team_evaluation
   * ignore_selection
   * ignore_pm_candidate
   * ignore_techlead_candidate
   * ignore_valuelead_candidate
   */

  return new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,

    // Scroll to page top on change route
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
  });
};
